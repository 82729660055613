import React, {useState, useEffect} from 'react';
import {StripeProvider, Elements} from 'react-stripe-elements';
import SEO from 'src/components/seo';
import {CheckoutForm} from 'src/components/subscription/checkoutForm';
import {api} from 'src/shared/api';
import {useScript} from 'src/shared/hooks/useScript';
import {browser} from 'src/shared/utilities/environment';
import {getSession} from 'src/shared/utilities/session';
import styles from './index.module.scss';

export default (props) => {
  useScript('stripe-js', 'https://js.stripe.com/v3/');
  const [stripeKey, setStripeKey] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeKeyLoading, setStripeKeyLoading] = useState(true);
  const [stripeScriptLoading, setStripeScriptLoading] = useState(true);
  const loading = stripeKeyLoading || stripeScriptLoading;

  useEffect(() => {
    const session = getSession();
    if (browser() && session && session.is_subscribed) {
      window.location = '/account';
    };

    api().get('/api/environment/')
        .then((response) => {
          setStripeKey(response.data.stripe_publishable_key);
          setStripeKeyLoading(false);
        })
        .catch((error) => {
          console.log('Failed to get publishable key', error);
          setStripeKeyLoading(false);
        });

    if (window.Stripe) {
      setStripeScriptLoading(false);
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripeScriptLoading(false);
      });
    }
  }, []);

  /**
   * Wait until both the Stripe script and key are ready before initializing
   */
  useEffect(() => {
    if (!stripeKeyLoading && !stripeScriptLoading) {
      setStripe(window.Stripe(stripeKey)); // eslint-disable-line
    }
  }, [stripeKeyLoading, stripeScriptLoading]);

  return (
    <div className={styles.pageWrapper}>
      <SEO title="Subscription" />
      {loading && <div>Loading...</div>}
      {!loading &&
        <StripeProvider stripe={stripe}>
          <Elements>
            <CheckoutForm cancelUrl={`/account`} />
          </Elements>
        </StripeProvider>
      }
    </div>
  );
};
