import React, {useState} from 'react';
import {IoIosCheckmark} from 'react-icons/io';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {Button} from 'src/components/ui/button';
import {Card} from 'src/components/ui/card';
import {TextInput} from 'src/components/ui/textInput';
import LogoWithText from 'src/images/logo_light_with_text.svg';
import StripeBadge from 'src/images/powered_by_stripe.svg';
import {api} from 'src/shared/api';
import formStyles from 'src/shared/styles/forms.module.scss';
import {setSessionData} from 'src/shared/utilities/session';
import styles from './checkoutForm.module.scss';

const benefits = [
  'Unlimited access to all features.',
  'No ads. Ever.',
  'Export your data.',
  'Advanced integrations (coming soon).',
  'Priority support.',
  'Cancel anytime.',
];

const pitch = 'Subscribe to Hacker Paper Pro and stay focused ' +
  'on the things that matter to you. Your subscription helps ' +
  'cover server costs, maintenance costs, development of new ' +
  'features, and helps me help you. Thank you for your business.';

export const CheckoutForm = injectStripe((props) => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [subscribeError, setSubscribeError] = useState(null);

  const resetValidation = () => {
    setNameError(null);
  };

  const validateForm = () => {
    let valid = true;
    resetValidation();

    if (name.length === 0) {
      setNameError('The name on the card is required.');
      valid = false;
    }

    return valid;
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (!validateForm()) {
      return;
    }

    props.stripe.createSource({
      type: 'card',
      currency: 'usd',
      owner: {
        name: name,
      },
    }).then((stripeResponse) => {
      if (stripeResponse.error) {
        setSubscribeError(stripeResponse.error.message);
        return;
      }
      api().post('/api/subscriptions/', stripeResponse)
          .then((response) => {
            setSessionData('is_subscribed', true);
            setSessionData('trial_expired', false);
            window.location = '/account';
          })
          .catch((error) => {
            setSubscribeError(error.response.data.message);
            console.log(error.response);
          });
    }).catch((error) => {
      setSubscribeError(error.message);
      console.log(error);
      alert('An error ocurred while subscribing. Try again or ' +
            'send an email to hackerpaperhelp@gmail.com.');
    });
  };

  return (
    <>
      <img src={LogoWithText} className={styles.logo} />
      <Card className={styles.checkoutForm}>
        <div className={styles.title}>Pro</div>
        <div className={styles.pricing}>
          <div className={styles.currency}><div>$</div></div>
          <div className={styles.amount}>15</div>
          <div className={styles.duration}><div>/month</div></div>
        </div>
        <div className={styles.info}>
          <div className={styles.pitch}>
            {pitch}
            <div className={styles.signature}>-Troy</div>
          </div>
          <div className={styles.benefits}>
            {benefits.map((text) => (
              <div>
                <IoIosCheckmark />
                {text}
              </div>
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={formStyles.inputGroup}>
            <label htmlFor="name">Name on card</label>
            <TextInput
              block
              name="name"
              value={name}
              errorMessage={nameError}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={formStyles.inputGroup}>
            <label htmlFor="">Card details</label>
            <Card className={styles.cardElementWrapper}>
              <CardElement
                style={{
                  base: {
                    fontSize: '18px',
                    color: '#484848',
                  },
                }}
              />
            </Card>
          </div>
          {subscribeError &&
            <div className={styles.errors}>
              {subscribeError}
            </div>
          }
          <Button block primary>Subscribe</Button>
        </form>
        <Button
          block
          link
          to={props.cancelUrl}
          className={styles.cancel}
        >
          Cancel
        </Button>
      </Card>
      <img className={styles.stripeBadge} src={StripeBadge} />
    </>
  );
});
